<template>
  <div>
    <div class="breadcrumb">
      <div class="row col-md-8">
        <h1 class="text-primary">#{{ ticket.id }} - {{ ticket.subject }}</h1>
      </div>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div v-if="ticket.is_open" class="col-4 float-right">
      <button
        class="btn btn-success float-right w-50"
        v-b-modal.new-support-modal
      >
        Reply
      </button>
    </div>
    <br />
    <div v-if="tickets.length > 0">
      <div v-for="ticket in tickets" :key="ticket.id" class="row mb-2">
        <div class="col-md-12">
          <Ticket :ticket="ticket" />
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <Ticket :ticket="ticket" />
      </div>
    </div>
    <b-modal
      id="new-support-modal"
      ref="new-support"
      size="lg"
      hide-footer
      hide-backdrop
    >
      <SupportForm
        @get-ticket-support="getTicketSupport"
        @hide-new-support-modal="hideNewSupportModal"
      />
    </b-modal>
  </div>
</template>

<script>
import SupportForm from "../../../components/Support/SupportForm";
import Ticket from "../../../components/Support/Ticket";
import axios from "axios";
export default {
  components: {
    SupportForm,
    Ticket
  },
  data() {
    return {
      ticket: {},
      tickets: []
    };
  },
  mounted() {
    this.getTicket();
    this.getTicketSupport();
  },
  methods: {
    hideNewSupportModal() {
      this.$refs["new-support"].hide();
    },
    async getTicket() {
      try {
        await axios
          .get(`v1/tickets/${this.$route.params.id}`)
          .then(response => {
            if (response.status === 200) {
              this.ticket = response.data;
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async getTicketSupport() {
      try {
        await axios
          .get(`v1/tickets/support/?ticket=${this.$route.params.id}`)
          .then(response => {
            if (response.status === 200) {
              this.tickets = response.data;
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
