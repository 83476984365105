<template>
  <div>
    <div class="row">
      <div class="col-md-12  mx-auto">
        <h4 class="text-center">{{ submit }}</h4>
        <div class="card mb-5">
          <div class="card-body">
            <form method="post" @submit.prevent="newSupportReply">
              <div class="d-flex flex-column">
                <div class="col-md-12 form-group mb-3">
                  <textarea
                    v-model="ticket.message"
                    class="form-control"
                    placeholder="message"
                    rows="10"
                    required
                  />
                </div>
                <button class="btn btn-success pd-x-20 w-100" type="submit">
                  {{ submit }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "SupportForm",
  data() {
    return {
      ticket: {
        message: ""
      },
      submit: "Send Reply"
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"])
  },
  methods: {
    async newSupportReply() {
      this.ticket.ticket = this.$route.params.id;
      this.ticket.user = this.loggedInUser.id;
      try {
        await axios.post("v1/tickets/support/", this.ticket).then(response => {
          if (response.status === 201) {
            this.$emit("get-ticket-support");
            this.$emit("hide-new-support-modal");
            this.$swal(
              "Success",
              "Reply submitted, we'll get back to you as soon as possible.",
              "success"
            );
          }
        });
      } catch (error) {
        if (error.response.data.detail) {
          this.$swal("Error", `${error.response.data.detail}`, "error");
        } else {
          this.$swal(
            "Error",
            `Could not reply to ticket at this time`,
            "error"
          );
        }
      }
    }
  }
};
</script>
