<template>
  <div class="card mb-4">
    <div class="card-body">
      <h5
        v-if="loggedInUser.id === ticket.user.id"
        class="card-title text-left text-success"
      >
        {{ ticket.user.name }}
        <span class="text-muted text-small"
          >({{ ticket.created | moment("dddd, MMMM Do YYYY h:mm a") }})</span
        >
      </h5>
      <h5 v-else class="card-title text-left text-primary">
        {{ ticket.user.name }}
        <span class="text-muted text-small"
          >({{ ticket.created | moment("dddd, MMMM Do YYYY h:mm a") }})</span
        >
      </h5>
      <p class="card-text text-left">
        {{ ticket.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Ticket",
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters(["loggedInUser"])
  }
};
</script>
